import { jwtDecrypt } from "../../shared/helper";

import {Preferences, SetOptions, GetOptions, RemoveOptions, KeysResult} from '@capacitor/preferences';
import axios from "axios";
import {Device} from "@capacitor/device";
import {bus} from '@/shared/bus';

const state = () => ({
    authData: {
        token: "",
        refreshToken: "",
        tokenExp: "",
        userId: '',
        userName: "",
    },
    loginStatus:'',
    verificationStatus:false,
});

const api = 'https://kraftplatz.app/';
//const api = 'http://localhost:8000/';

const getters = {
    getLoginStatus(state: any){
        return state.loginStatus;
    },

    getAuthData(state: any){
        return state.authData;
    },

    getVerificationStatus(state: any){
        return state.verificationStatus;
    }

};

const actions = {
    async logoutUser({commit, state}: any, payload: any){
        if(state.loginStatus != 'logout') {
            axios.post(api + 'api/logout')
                .catch((response) => {
                    console.log('Logout hat nicht funktioniert');
                    console.log(response);
                    commit('saveLoginStatus', 'logout');
                    const tokenData = {
                        token: null
                    };
                    commit("deleteAuthToken", tokenData);
                })
                .then(response => {
                    console.log(response);
                    console.log('Logout User');
                    const tokenData = {
                        token: null
                    };
                    delete axios.defaults.headers.common["Authorization"];
                    commit('saveLoginStatus', 'logout');
                    commit("deleteAuthToken", tokenData);
                    bus.$emit('logedout', true);
                });
        }
    },

    async checkUser({commit, state}: any, payload: any){
        await axios.get(api + 'api/admin/user/self').then(async (response) => {
            bus.$emit('loadProfil', response.data.user);

            if(response.data.user.verified == false)
                bus.$emit('verification', false);

            // commit("saveAuthToken", response.data);
            await Preferences.set({
                key: 'user',
                value: JSON.stringify(response.data.user),
            });
            commit("saveVerificationStatus", response.data.user.verified)
            bus.$emit('loadProfil', response.data.user);

        }).catch((error) => {
            if(error.response) {
                if (error.response.status == 403 && error.response.data.message == "Your email address is not verified.")
                    bus.$emit('verification', false);
            }
        });
    },

    async loginUser({commit}: any, payload: any){
        try {
            console.log(payload);
            const info = await Device.getInfo();
            payload.device_name = info.model;

            axios.defaults.withCredentials = true;
            const csrfResponse = await axios.get(api + 'sanctum/csrf-cookie');
            console.log(csrfResponse);

            const loginResponse = await axios.post(api + 'api/login', payload);
            console.log(loginResponse);

            if (loginResponse.status === 200 || loginResponse.status === 201) {
                await Preferences.set({
                    key: 'access_token',
                    value: loginResponse.data.access_token,
                });
                console.log('access token set');

                axios.defaults.headers.common = {
                    'Authorization': 'Bearer ' + loginResponse.data.access_token,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }

                commit("checkUser");

                commit("saveLoginStatus", "success");

                return { message: 'success' };
            } else {
                await Preferences.set({
                    key: 'access_token',
                    value: '',
                });
                console.log('access token set');

                console.log('SaveLoginStatus: failed');
                commit("saveLoginStatus", "failed");
                console.log(loginResponse.data);
                return { message: loginResponse.data.message };
            }
        } catch (error: any) {
            await Preferences.set({
                key: 'access_token',
                value: '',
            });
            console.log('access token set');

            console.log('SaveLoginStatus: failed');
            commit("saveLoginStatus", "failed");
            console.log("Errormessage");
            //console.log(error.response.data.message);
            //return { message: error.response.data.message };
        }

        Preferences.get({
            key: 'access_token',
        }).then((val) => {
            console.log('Aus login funktion: ' + val.value);
        });

        return { message: "Unbekannter Fehler." };
    },

    async registerUser({commit}: any,payload: any){
        const info = await Device.getInfo();
        payload.device_name = info.model;

        console.log(payload);


        axios.defaults.withCredentials = true;
        await axios.get(api + 'sanctum/csrf-cookie').then(async (response) => {
            console.log(response);
            await axios.post(api + 'api/register', payload).then(async (response) => {
                //alert(response.data);
                console.log(response);
                if (response.status == 200 || response.status == 201) {
                    await Preferences.set({
                        key: 'access_token',
                        value: response.data.access_token,
                    }).then(() =>{ console.log('access token set') });

                    axios.defaults.headers.common = {
                        'Authorization': 'Bearer '+response.data.access_token,
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }

                    axios.get(api + 'api/admin/user/self').then(async (response) => {
                        console.log(response);
                    }).catch(() => {console.log('fehler')});

                    //commit("saveAuthToken", response.data);
                    commit("saveLoginStatus", "success");

                    console.log(response.data.user);
                    await Preferences.set({
                        key: 'user',
                        value: JSON.stringify(response.data.user),
                    });
                    commit("saveVerificationStatus", response.data.user.verified)
                    bus.$emit('loadProfil', response.data.user);
                } else {
                    await Preferences.set({
                        key: 'access_token',
                        value: '',
                    }).then(() =>{console.log('access token set')});
                    console.log('SaveLoginStatus: failed');
                    commit("saveLoginStatus", "failed");
                }
            }).catch(response => {
                //alert(response.data);
                //alert('Fehler bei der Registration');
            });
        });
    },

    async loadStorageTokens({ commit }: any) {
        const access_token = await Preferences.get({ key: "access_token" });
        if(access_token.value) {
            console.log(access_token.value);
            axios.defaults.headers.common = {
                'Authorization': 'Bearer '+ access_token.value,
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
            commit("saveLoginStatus", "success");
            bus.$emit('logedin', true);
            return true;
        }
        else
            return false;
        const refresh_token = await Preferences.get({ key: "refresh_token" });
        if (access_token && refresh_token) {
            const tokenData = {
                access_token: access_token.value,
                refresh_token: refresh_token.value
            };
            commit("saveAuthToken", tokenData);
        }
    }
};

const mutations = {
    saveAuthToken(state: any, payload: any) {
        const jwtDecodeUserInfo = jwtDecrypt(payload.access_token);
        const newAuthData = {
            token: payload.access_token,
            refreshToken: payload.refresh_token,
            tokenExp: jwtDecodeUserInfo.exp,
            userId: jwtDecodeUserInfo.sub,
            userName: jwtDecodeUserInfo.username,
        };
        state.authData = newAuthData;
    },
    saveLoginStatus(state: any, status: any){
        console.log('Neuer Status gesetzt: '+state.loginStatus+' zu '+ status);
        state.loginStatus = status;
    },
    saveVerificationStatus(state: any, status: any){
        console.log('Verifikationsstatus gesetzt: '+state.verificationStatus+' zu '+ status);
        state.verificationStatus = status;
    },
    deleteAuthToken(state: any, payload: any){
        state.authData.token = null;
        Preferences.set({
            key: 'access_token',
            value: '',
        }).then(() =>{console.log('access token deleted')});
        console.log('Token deleted');
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};