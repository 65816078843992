
import { IonApp, IonContent, IonLabel, IonMenu, IonRouterOutlet, IonSplitPane, IonHeader, IonGrid, IonRow, IonCol, IonToolbar, toastController, IonSearchbar, IonTitle, IonList, IonListHeader, IonNote, IonFooter, IonIcon, IonButton, IonModal, IonInput, IonCheckbox, IonItem, IonText, IonAlert, alertController, modalController } from '@ionic/vue';
import { defineComponent, Ref, ref } from 'vue';
import { isPlatform } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { archiveOutline, archiveSharp, callSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, moon, logIn, logOut, chatbox} from 'ionicons/icons';
import DarkModeToggleButton from '@/components/DarkModeToggleButton.vue';
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import {Preferences} from '@capacitor/preferences';
import {Device} from '@capacitor/device';
import {OverlayEventDetail} from "@ionic/core/components";
import {Capacitor} from "@capacitor/core";
import {bus} from "@/shared/bus";
import { DeviceMotion, DeviceMotionAccelerationData } from '@ionic-native/device-motion';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import 'swiper/css';

const api = 'https://kraftplatz.app/';
export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonList,
    IonListHeader,
    IonNote,
    IonFooter,
    IonIcon,
    IonButton,
    DarkModeToggleButton,
    IonLabel,
    IonModal,
    IonInput,
    IonCheckbox,
    IonSearchbar,
    IonItem,
    IonText,
    IonAlert,
    Swiper,
    SwiperSlide,
    IonGrid,
    IonRow,
    IonCol,
  },
  computed: {
    ...mapGetters("auth", {
      loginStatus: "getLoginStatus",
    }),
    loginModal(): any {
      return (this.$refs['loginModal'] as any);
    },
  },
  methods:{

    playAudio1 () {
      const audioPlayer = (this.$refs['audioPlayer1'] as any);
      audioPlayer.play();
    },
    playAudio2 () {
      const audioPlayer = (this.$refs['audioPlayer2'] as any);
      audioPlayer.play();
    },
    playAudio3 () {
      const audioPlayer = (this.$refs['audioPlayer3'] as any);
      audioPlayer.play();
    },
    playAudio4 () {
      const audioPlayer = (this.$refs['audioPlayer4'] as any);
      audioPlayer.play();
    },
    playAudio5 () {
      const audioPlayer = (this.$refs['audioPlayer5'] as any);
      audioPlayer.play();
    },
    playAudio6 () {
      const audioPlayer = (this.$refs['audioPlayer6'] as any);
      audioPlayer.play();
    },
    playAudio7 () {
      const audioPlayer = (this.$refs['audioPlayer7'] as any);
      audioPlayer.play();
    },
    playAudio8 () {
      const audioPlayer = (this.$refs['audioPlayer8'] as any);
      audioPlayer.play();
    },
    playAudio9 () {
      const audioPlayer = (this.$refs['audioPlayer9'] as any);
      audioPlayer.play();
    },
    playAudio10 () {
      const audioPlayer = (this.$refs['audioPlayer10'] as any);
      audioPlayer.play();
    },

      onSwiper(swiper: typeof Swiper) {
        this.swiper = swiper;
      },

      slidesModalDismiss(){
        modalController.dismiss();
      },

      handleSlideTo(){
        this.swiper.slideTo(3);
      },

      sizes(){
        let buttons = document.getElementsByClassName('actButton ion-text-wrap');
        for(let i = 0; i < buttons.length; i++){
          let button = buttons[i] as HTMLElement;
          button.style.height = ((this.$refs['slidesModal'] as any).$el.offsetHeight/8) + 'px';
          button.style.fontSize = '1.2em';
        }
        (this.$refs['slidesModal'] as any).$el.style.opacity = 1;
        console.log();
      },

      async startDeviceMotion(){
        const platform = Capacitor.getPlatform();

        if(platform != 'web') {

          DeviceMotion.getCurrentAcceleration().then((acceleration: DeviceMotionAccelerationData) => {
            this.handleAcceleration(acceleration);
          });

          DeviceMotion.watchAcceleration({ frequency: 40 }).subscribe((acceleration: DeviceMotionAccelerationData) => {
            bus.$emit('shaked', true);
            this.handleAcceleration(acceleration);
          });
        }
      },
      handleAcceleration(acceleration: DeviceMotionAccelerationData) {
        const { x, y, z } = acceleration;
        if (x > 10 || y > 10 || z > 10) {
          this.handleShake();
          console.log('Das Handy wurde geschüttelt!');
        }
      },
      handleShake(){
        bus.$emit('shaked', true);
      },

      async showAlert() {
        const toast = await toastController.create({
          message: 'Shaked Alert.',
          duration: 3000,
          cssClass: 'custom-toast',
          position: 'bottom',
        });

        await toast.present();
      },

      async scheduleNotification() {
        console.log('Schedule Notification');
      },

      async themeToggle() {
        document.body.classList.toggle('dark');
      },

      async onLoginSubmit () {
        console.log('Login Status before: ' + this.loginStatus);
        if (this.userInfo.email && this.userInfo.password) {
          const response = await this.loginUser(this.userInfo);
          console.log(response);
          if(response.message == "success"){
            console.log('OnLoginSubmitSuccess:');
            console.log(response);
            (this.$refs['modalLogin'] as any).$el.dismiss();
            this.userInfo.email = '';
            this.userInfo.password = '';
          }else{
            this.loginError = response.message;
            console.log('OnLoginSubmit: failed to login');
          }
        }
        console.log('Login Status after: ' + this.loginStatus);
      },

      async onRegisterSubmit () {
        if(!this.validateEmail(this.registerInfo.email)) {
          this.error = "Email im Format muster@muster.com";
          return;
        }
        if(!this.validatePassword(this.registerInfo.password)) {
          this.error = "Password muss min. 8 Zeichen, ein Sonderzeichen !@#$%^&.* und eine Zahl enthalten.";
          return;
        }
        if(!this.validatePasswordConfirm(this.registerInfo.password)) {
          this.error = "Passwörter stimmen nicht überein.";
          return;
        }
        if(!this.validateAGBS(this.registerInfo.agbs)) {
          this.error = "Die AGB's müssen gelesen und akzeptiert werden.";
          return;
        }


        if (this.registerInfo.email && this.registerInfo.password && this.registerInfo.password_confirmation && this.registerInfo.agbs) {
          await this.registerUser(this.registerInfo);
          if(this.loginStatus === "success"){
            console.log('OnLoginSubmit: login succes');
            (this.$refs['modalRegister'] as any).$el.dismiss();
            this.registerInfo.agbs = false;
            this.registerInfo.newsletter = true;
            this.registerInfo.password = '';
            this.registerInfo.password_confirmation = '';
            this.registerInfo.email = '';
            (this.$refs['verificationAlert'] as any).$el.present();
          }else{
            console.log('OnLoginSubmit: failed to login');
          }
        }
        console.log('Login Status after: ' + this.loginStatus);
      },

      validateEmail(email: any) {
        var errorString = "Email muss im Format muster@muster.com";
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
          (this.$refs['registerInfoEmail'] as any ).$el.classList.remove('invalid');
          if(this.error == errorString)
            this.error = '';
          return true;
        } else {
          (this.$refs['registerInfoEmail'] as any ).$el.classList.add('invalid');
          this.error = errorString;
          return false;
        }
      },

      validatePassword(value: any) {
        var errorString = "Passwort muss min. 8 Zeichen, ein Sonderzeichen !@#$%^&.* und eine Zahl enthalten.";
        if (/^(?=.*\d)(?=.*[!@#$%^&.*])(?=.*[a-zA-Z]).{8,}$/.test(value)) {
          (this.$refs['registerInfoPassword'] as any ).$el.classList.remove('invalid');
          if(this.error == errorString)
            this.error = '';
          return true;
        } else {
          (this.$refs['registerInfoPassword'] as any ).$el.classList.add('invalid');
          this.error = errorString;
          return false;
        }
      },

      validatePasswordConfirm(value: any) {
        var errorString = "Passwörter stimmen nicht überein.";
        if ((this.registerInfo.password == this.registerInfo.password_confirmation)) {
          if (this.error == errorString)
            this.error = '';
          if(!this.validatePassword(this.registerInfo.password_confirmation)) {
            (this.$refs['registerInfoPasswordConfirm'] as any).$el.classList.add('invalid');
            return false;
          }

          (this.$refs['registerInfoPasswordConfirm'] as any).$el.classList.remove('invalid');
          return true;
        } else {
          (this.$refs['registerInfoPasswordConfirm'] as any ).$el.classList.add('invalid');
          this.error = errorString;
          return false;
        }
      },

      validateAGBS(value: any) {
        var errorString = "Die AGB's müssen gelesen und akzeptiert werden.";
        if (value == true) {
          (this.$refs['registerInfoAGBS'] as any ).$el.classList.remove('invalid');
          if(this.error == errorString)
            this.error = '';
          return true;
        } else {
          (this.$refs['registerInfoAGBS'] as any ).$el.classList.add('invalid');
          this.error = errorString;
          return false;
        }
      },

      openRegister () {
        modalController.dismiss();
        (this.$refs['modalRegister'] as any).$el.present();
      },

      openLogin () {
        modalController.dismiss();
        (this.$refs['modalLogin'] as any).$el.present();
      },

      /*onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
        if (ev.detail.role === 'confirm') {
          this.message = `Hello, ${ev.detail.data}!`;
        }
      },*/

      async presentUnauthenticatedAlert() {
        const loginModal = (this.$refs['modalLogin'] as any).$el;
        const alert = await alertController.create({
          header: 'Login',
          subHeader: 'Nicht angemeldet',
          message: 'Es scheint als wärst du nicht eingeloggt oder die Session abgelaufen. Bitte loge dich neu ein.',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
            },
            {
              text: 'Login',
              role: 'login',
              handler: () => {
                loginModal.present();
              }
            }
          ],
        });

        await alert.present();
      },

      ...mapActions("auth", {
        loginUser: "loginUser",
        logoutUser: "logoutUser",
        registerUser: "registerUser",
        loadStorageTokens: "loadStorageTokens",
        checkUser: "checkUser",
      }),

      async sendVerificationCode(){
        console.log('Verifizierungs Link neu senden');
        axios.get(api + 'api/auth/verify').catch(
            response => {
              console.log('Verifizierungs Link konnte nicht gesendet werden.');
              console.log(response);
            }
        );

        const toast = await toastController.create({
          message: 'Email zur Verifizierung wurde gesendet.',
          duration: 3000,
          cssClass: 'custom-toast',
          position: 'bottom',
        });

        await toast.present();
      },

      async getUserInfo(){
        axios.get(api + 'api/admin/user/self').then(response => {
          console.log(response);
        });
        const info = await Device.getInfo();
        const id = await Device.getId();
        console.log(info);
        console.log(id);
        console.log(await Device.getBatteryInfo());
      },
      async logout(){
        console.log('Login Status before logout: ' + this.loginStatus);
        await this.logoutUser();
        if(this.loginStatus == "logout"){
          console.log('Benutzer ausgeloggt');
        }else{
          console.log('Anderer Status: ' + this.loginStatus);
        }
        console.log('Login Status after logout: ' + this.loginStatus);
      },
      async setPreference(){
        axios.get(api + 'api/logout').catch(
            response => {
              console.log('Logout hat nicht funktioniert');
              console.log(response);
            }
        );

        Preferences.set({
          key: 'test',
          value: 'es funktioniert wieso',
        }).then((val)=> {
          alert('fuck this setter');
        });
      },
      async getPreference() {
        Preferences.get({
          key: 'access_token',
        }).then((val) => {
          alert('fuck this getter ' + val.value);
        });
      }
  },
  watch: {
    "registerInfo.email"(){
      this.validateEmail(this.registerInfo.email);
    },
    "registerInfo.password"(){
      this.validatePassword(this.registerInfo.password);
    },
    "registerInfo.password_confirmation"(){
      this.validatePasswordConfirm(this.registerInfo.password_confirmation);
    },
    "registerInfo.agbs"(){
      this.validateAGBS(this.registerInfo.agbs);
    }
  },
  setup() {

    const route = useRoute();


    return {
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp,
      callSharp,
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      moon,
      logIn,
      logOut,
      chatbox,
      isSelected: (url: string) => url === route.path ? 'selected' : '',
      watch: '',
    }
  },
  data() {
    return {
      audioSource1: '/assets/audio/1.wav',
      audioSource2: '/assets/audio/2.wav',
      audioSource3: '/assets/audio/3.wav',
      audioSource4: '/assets/audio/4.wav',
      audioSource5: '/assets/audio/5.wav',
      audioSource6: '/assets/audio/6.wav',
      audioSource7: '/assets/audio/7.wav',
      audioSource8: '/assets/audio/8.wav',
      audioSource9: '/assets/audio/9.wav',
      audioSource10: '/assets/audio/10.wav',
      userInfo: {
        email: "",
        password: "",
      },
      registerInfo: {
        email: "",
        password: "",
        password_confirmation: "",
        agbs: false,
        newsletter: true,
      },
      registerInfoValid: {
        email: true,
        password: true,
        password_confirmation: true,
        agbs: false,
        newsletter: true,
      },
      popoverOpen: false,
      message: 'Was geht ab.',
      error: '',
      loginError: '',
      swiper: null as any,
    };
  },
  created() {
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if(error.response.status === 401) {
        bus.$emit('unauthenticated', true);
      }
      if(error.response.status == 403 && error.response.data.message == "Your email address is not verified.") {
        bus.$emit('verification', false);
      }
      return error;
    });
    bus.$on('slidesModal', (mode: boolean) => {
      (this.$refs['slidesModal'] as any).$el.present();
    });
    bus.$on('loginModal', (mode: boolean) => {
      (this.$refs['modalLogin'] as any).$el.present();
    });
    bus.$on('shaked', (mode: boolean) => {
      this.showAlert();
    });
    bus.$on('checkUser', (mode: boolean) => {
      this.checkUser();
    });
    bus.$on('assessment', (mode: boolean) => {
      (this.$refs['modalAssessment'] as any).$el.present();
    });
    bus.$on('verification', (mode: boolean) => {
      (this.$refs['verificationAlert'] as any).$el.present();
    });
    bus.$on('unauthenticated', async (mode: boolean) => {
      (this.$refs['unauthenticatedAlert'] as any).$el.present();
      await this.logoutUser();
      if(this.loginStatus == "logout"){
        console.log('Benutzer ausgeloggt');
      }else{
        console.log('Anderer Status: ' + this.loginStatus);
      }
    });
  },
  mounted(){
    this.loadStorageTokens();


    Preferences.get({
      key: 'user',
    }).then((val) => {
      console.log('User Values:');
      var user = {};
      try{
        user = JSON.parse((val.value != null) ? val.value : '');
      } catch (error) {
        console.log('User Values not working');
      }
      if(val.value)
        bus.$emit('loadProfil', user);
    });
    this.startDeviceMotion();


  }

});
